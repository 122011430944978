/* General */

html {
  font-family: "BabyDoll";
  overflow-y: auto;
  background-image: linear-gradient(to bottom right, #1D538F, #9BDCDE);
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
}

.mapboxgl-ctrl-attrib-inner{
  font-size: 1.3em;
}

p {
  font-size: 28px;
  margin: 10px 0px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 32px;
}

label {
  font-weight: bold;
  font-size: 28px;
  width: auto;
}

.overlay{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
  /* background-color: rgba(0,0,0,0.5); dim the background */
}

.overlay::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: repeat;
  height: 10px;
  background-size: 20px 20px;
  background-image: radial-gradient(circle at 10px -5px, transparent 12px, rgba(45, 137, 223, 0.909) 13px);
}

.overlay::after{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: repeat;
  height: 15px;
  background-size: 40px 20px;
  background-image: radial-gradient(circle at 10px 15px, rgba(45, 137, 223, 0.909) 12px, transparent 13px);
}

@font-face {
  font-family: "BabyDoll";
  font-style: normal;
  font-weight: normal;
  src: local('BabyDoll'), url("./BabyDoll.ttf") format('truetype');
}

button {
  font-family: "BabyDoll";
  background-color: #FD7066;
  border-radius: 7px;
  border: none;
  color: white;
  padding: 15px 15px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 4ms;
}

button:hover {
  background-color: #DD98AB;
}

/* Navbar */

.nav-container {
  border-radius: 12px;
  margin: 30px;
  height: 70px;
  background-color: #273155;
  color: #9BDCDE;
  font-size: 28px;
}

ul {
  width: 100%;
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

ul li {
  height: inherit;
  float: left;
}

ul li a {
  height: fit-content;
  display: inline-block;
  padding: 20px 20px;
  color: #9BDCDE;
  text-decoration: none;
}

.sidebar {
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
  z-index: 20;
}

/* Login */

.login-box {
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: auto;
  width: 400px;
  height: 400px;
  background-color: ghostwhite;
}

.login-box > label {
  float: left;
  color: midnightblue;
  width: 80%;
  margin-top: 10px;
  padding-top: 10px;
  font-size: 16px;
}

.login-box > input {
  width: 80%;
  height: 20px;
}

.login-box > button {
  color: midnightblue;
  margin: auto;
  text-align: center;
  margin-top: 40px;
  width: 40%;
}

.login-box > h3 {
  font-size: 32px;
}

.img-container {
  position: absolute;
  background-image: url(/public/jelly1.png), url(/public/jelly2.png), url(/public/jelly3.png), url(/public/jelly2.png);
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: top left,
  top right ,
  bottom right,
  bottom left;
  z-index: 10;
  pointer-events: none;
  margin: auto;
  width: 100%;
  aspect-ratio: 3/2;
}

/* Map */

.map-container {
  height: 70vh;
  margin: auto;
  border: 8px solid rgb(60, 93, 125);
  aspect-ratio: 3/2;
}

.mapboxgl-popup-content {
  font-family: "BabyDoll";
}

.mapboxgl-popup {
  z-index: 20;
}

.mapboxgl-popup a {
  font-size: 28px;
}

#login {
  float: right;
  display: flex;
  align-items: center;
}

/* Mobile Sizing Overrides*/

@media (min-width: 320px) and (max-width: 999px) {

  .map-container {
    margin-top: 16px;
    width: 80vw;
    border: 12px solid rgb(255 255 255 / 0.85);
    aspect-ratio: 1/2;
  }

  .nav-container {
    margin: 0;
  }
  
  a {
    height: 15px;
  }

  p {
    font-size: 22px;
  }

  h2 {
    font-size: 26px;
  }
  
  h3 {
    font-size: 24px;
  }

  label {
    font-size: 20px;
  }

  .mapboxgl-popup a {
    font-size: 22px;
  }

  .img-container {
    width: 90%;
    aspect-ratio: 1/2;
  }

  li > label {
    display: none;
    white-space: normal;
    font-size: 12px;
  }

  #login {
    font-size: 16px;
    float: left;
  }

  .login-box {
    width: inherit;
  }
}